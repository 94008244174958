import {
  QUERY_CACHE_TIME,
  QUERY_STALE_TIME,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/shared/src/services/query";

import { Country, WareHouse } from "../../types/common/common";

import {
  GET_USER_COUNTRY_LIST_REQ,
  GET_USER_LINER_LIST_RES,
  GET_USER_PORT_LIST_REQ,
  GET_USER_PORT_LIST_RES,
  GET_USER_ZONE_LIST_REQ,
  GET_USER_ZONE_LIST_RES,
} from "../../api-interfaces/shipda-api/common";

export const COMMON_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/COMMON_QUERY" }] as const,

  getExchangeRate: () =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        entity: "exchangeRate",
      },
    ] as const,

  getUserCountryList: (params: GET_USER_COUNTRY_LIST_REQ) =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "userCountryList",
      },
    ] as const,

  getUserPortList: (params: GET_USER_PORT_LIST_REQ) =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "userPortList",
      },
    ] as const,

  getUserLinerList: () =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        entity: "userLinerList",
      },
    ] as const,

  getUserWarehouseList: () =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        entity: "userWarehouseList",
      },
    ] as const,

  getUserZoneList: (params: GET_USER_ZONE_LIST_REQ) =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "userZoneList",
      },
    ] as const,
};

function useGetUserCountryList({
  enabled,
  staleTime = QUERY_STALE_TIME.halfHour,
  cacheTime = QUERY_CACHE_TIME.halfHour,
  hidesLoading,
  ...params
}: GET_USER_COUNTRY_LIST_REQ & {
  enabled?: boolean;
  staleTime?: number;
  cacheTime?: number;
  hidesLoading?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getUserCountryList>,
    Country[]
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getUserCountryList(params),
    requestOptions: {
      method: "get",
      path: "/countries",
      apiType: "SHIPDA_API",
      params,
    },
    staleTime,
    cacheTime,
    enabled,
    hidesLoading,
  });

  return { ...queryResult };
}

function useGetUserPortList({
  enabled,
  staleTime = QUERY_STALE_TIME.halfHour,
  cacheTime = QUERY_CACHE_TIME.halfHour,
  select,
  ...params
}: GET_USER_PORT_LIST_REQ & {
  enabled?: boolean;
  staleTime?: number;
  cacheTime?: number;
  select?: (res: GET_USER_PORT_LIST_RES) => GET_USER_PORT_LIST_RES;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getUserPortList>,
    GET_USER_PORT_LIST_RES
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getUserPortList(params),
    requestOptions: {
      method: "get",
      path: "/ports",
      params,
      apiType: "SHIPDA_API",
    },
    staleTime,
    cacheTime,
    enabled,
    select,
  });

  return { ...queryResult };
}

/**
 * 전체 선사 상세 정보 조회
 * 선사 정보를 필터링 할 때에는, getLinerInfo 함수를 사용해야 함. (LCL & linerId null 인 경우, 쉽다 표기 처리)
 */
function useGetUserLinerList({
  enabled,
  staleTime = QUERY_STALE_TIME.halfHour,
  cacheTime = QUERY_CACHE_TIME.halfHour,
}: {
  enabled?: boolean;
  staleTime?: number;
  cacheTime?: number;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getUserLinerList>,
    GET_USER_LINER_LIST_RES
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getUserLinerList(),
    requestOptions: {
      method: "get",
      path: "/liners",
      apiType: "SHIPDA_API",
    },
    staleTime,
    cacheTime,
    enabled,
  });

  return { ...queryResult };
}

function useGetUserWarehouseList({
  staleTime = QUERY_STALE_TIME.halfHour,
  cacheTime = QUERY_CACHE_TIME.halfHour,
  enabled,
}: {
  staleTime?: number;
  cacheTime?: number;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getUserWarehouseList>,
    WareHouse[]
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getUserWarehouseList(),
    requestOptions: {
      method: "get",
      path: "/warehouses",
      apiType: "SHIPDA_API",
    },
    staleTime,
    cacheTime,
    enabled,
  });

  return { ...queryResult };
}

function useGetUserZoneList({
  enabled,
  onSuccess,
  staleTime = QUERY_STALE_TIME.halfHour,
  cacheTime = QUERY_CACHE_TIME.halfHour,
  ...params
}: {
  enabled?: boolean;
  onSuccess?: (res: GET_USER_ZONE_LIST_RES) => void;
  staleTime?: number;
  cacheTime?: number;
} & GET_USER_ZONE_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getUserZoneList>,
    GET_USER_ZONE_LIST_RES
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getUserZoneList(params),
    requestOptions: {
      method: "get",
      path: "/zones",
      params,
      apiType: "SHIPDA_API",
    },
    enabled,
    onSuccess,
    staleTime,
    cacheTime,
  });

  return { ...queryResult };
}

function useGetUserExchangeRate({
  onSuccess,
  staleTime = QUERY_STALE_TIME.halfHour,
  cacheTime = QUERY_CACHE_TIME.halfHour,
}: {
  onSuccess?: (res: number) => void;
  staleTime?: number;
  cacheTime?: number;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getExchangeRate>,
    number
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getExchangeRate(),
    requestOptions: {
      method: "get",
      path: "/exchange",
      apiType: "SHIPDA_API",
    },
    onSuccess,
    cacheTime,
    staleTime,
  });

  return { ...queryResult };
}

const COMMON_QUERY = {
  useGetUserExchangeRate,
  useGetUserCountryList,
  useGetUserPortList,
  useGetUserLinerList,
  useGetUserWarehouseList,
  useGetUserZoneList,
};

export default COMMON_QUERY;

import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const desktopContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 14px;
  transform: translate(0%, -50%);
  z-index: 1;
  text-align: center;

  ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  a {
    width: 68px;
    height: 63px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 8px;
    background-color: ${COLOR.bk_40};
    gap: 4px;
    border-radius: 2px;
    cursor: pointer;

    > .label {
      color: ${TEXT_COLOR.white_2};
      ${setFontStyle("Body5")};
    }
  }
`;

const mobileContainer = styled.div<{
  baseBottomPosition: number;
}>`
  position: fixed;
  top: auto;
  bottom: 0;
  right: 14px;
  z-index: 10;

  ul {
    ${({ baseBottomPosition }) =>
      css`
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
        bottom: ${baseBottomPosition + 56}px;
      `}

    &.opened-actions {
      transform: scale(1);
      transition: all 0.1s ease-in-out;
    }

    &.closed-actions {
      transform: scale(0);
      transition: all 0.2s ease-in-out 0.5s;
    }
  }

  .animation-wrapper,
  .toggle-button {
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid ${COLOR.bk_60};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .toggle-button {
    ${({ baseBottomPosition }) =>
      css`
        width: 44px;
        height: 44px;
        border-radius: 50%;
        z-index: 10;
        bottom: ${baseBottomPosition}px;
      `}

    .trigger {
      cursor: pointer;
    }
  }

  .toggle-button {
    position: absolute;
    right: 0;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &.opened-actions {
      transform: rotate(45deg);
    }
  }
`;

const commonQuickActionItemStyle = css`
  ${mobile(css`
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
  `)};
`;

/**  action type 은 li 에 적용, link type 은 a 에 적용 */
const mobileQuickActionItem = styled.li<{
  type: "link" | "action";
  translateY: number;
  minScaleX: number;
}>`
  position: relative;

  .animation-wrapper {
    ${({ type }) =>
      type === "action"
        ? commonQuickActionItemStyle
        : css`
            a {
              ${commonQuickActionItemStyle}
            }
          `};

    ${mobile(css`
      width: max-content;
      color: ${COLOR.bk_60};
    `)};
  }

  &.closed-actions {
    opacity: 0;
    transform: translateY(${({ translateY }) => translateY || 0}px);
    transition: all 0.2s ease-in-out 0.1s;

    .animation-wrapper {
      transform-origin: right center;
      transform: scaleX(${({ minScaleX }) => minScaleX || 0});
      border-radius: 100%;
      transition: all 0.2s ease-in-out 0.1s;

      .label {
        opacity: 0;
        transition: all 0.1s ease-in-out;
      }
    }
  }

  &.opened-actions {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-in-out;

    .animation-wrapper {
      transform-origin: right center;
      transform: scaleX(1);
      border-radius: 40px;
      transition: all 0.2s ease-in-out 0.1s;

      .label {
        opacity: 1;
        transition: all 0.2s ease-in-out 0.3s;
      }
    }
  }

  .label,
  a {
    ${setFontStyle("Body4")};
    color: ${COLOR.bk_60};
  }

  .label {
    padding-right: 22px;
  }

  // 아이콘이 scale 영향으로 찌그러지지 않게 하기 위해서 위치 따로 설정
  svg {
    position: absolute;
    top: 10px;
    right: 12px;
  }
`;

export default { desktopContainer, mobileContainer, mobileQuickActionItem };

import Link from "next/link";

import { addEventToGTMDataLayer } from "@sellernote/shared/src/utils/common/gtm";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import { QuickActionItem } from ".";
import Styled from "./index.styles";

export default function QuickActionsForDesktop({
  list,
}: {
  list: QuickActionItem[];
}) {
  return (
    <Styled.desktopContainer>
      <ul>
        {list.map(({ label, path, Icon, gtmEvent, isExternalLink }) => (
          <li key={label}>
            <Link href={path || ""}>
              <a
                {...(isExternalLink && {
                  onClick: () => {
                    if (!gtmEvent) {
                      return;
                    }

                    addEventToGTMDataLayer(gtmEvent);
                  },
                  target: "_blank",
                  rel: "noopener noreferrer",
                })}
              >
                <Icon color={COLOR.wh_60} width={24} height={24} />

                <span className="label">{label}</span>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </Styled.desktopContainer>
  );
}

import { useEffect, useState } from "react";

import useIntersectionObserver from "@sellernote/shared/src/hooks/common/useIntersectionObserver";

/**
 * intersection observer 의 isActiveTarget 이 최초에 타겟이 active 되었을 때, true를 저장해 return 하는 hook
 */
export default function useOnceActivatedIntersectionObserver({
  threshold,
}: {
  threshold?: number;
}) {
  const { target, isActiveTarget } = useIntersectionObserver({
    threshold,
  });

  const [isActivatedTarget, setIsActivatedTarget] = useState(false);

  useEffect(() => {
    if (isActiveTarget) setIsActivatedTarget(true);
  }, [isActiveTarget]);

  return { target, isActivatedTarget };
}

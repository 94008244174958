import { useState } from "react";
import { useAtomValue } from "jotai";
import Link from "next/link";
import Router from "next/router";

import { noop } from "@sellernote/shared/src/utils/common/etc";
import { addEventToGTMDataLayer } from "@sellernote/shared/src/utils/common/gtm";
import PlusSolidIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/PlusSolidIcon";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import { MOBILE_CHAT_BUTTON_HEIGHT } from "../../../constants/layout";
import { COMMON_LAYOUT_ATOMS } from "../../../jotaiStates/common/layout";
import { QuickActionItem } from ".";
import Styled from "./index.styles";

/**
 * (모바일) 채널톡 아이콘과 퀵액션 사이의 거리
 */
const MOBILE_GAP_BETWEEN_CHAT_BUTTON_AND_QUICK_ACTIONS = 16;

export default function QuickActionsForMobile({
  list,
}: {
  list: QuickActionItem[];
}) {
  const [opens, setOpens] = useState(false);

  const floatingButtonAreaPosition = useAtomValue(
    COMMON_LAYOUT_ATOMS.FLOATING_BUTTON_AREA_POSITION
  );

  // 도입문의의 경우, 글자 수가 적어 애니메이션 시작 시, 최소로 줄어드는 사이즈의 비율을 좀 더 크게 반영.
  const mobileQuickActionItemMinScaleX = (label: string) => {
    return label.length < 5 ? 0.45 : 0.35;
  };

  const getCommonQuickActionItemProps = ({
    label,
    i,
  }: {
    label: string;
    i: number;
  }) => ({
    key: label,
    className: `${opens ? "opened-actions" : "closed-actions"}`,
    translateY: Math.abs(i - 3) * 50,
    minScaleX: mobileQuickActionItemMinScaleX(label),
  });

  /**
   * 퀵액션 버튼의 bottom 위치
   */
  const baseBottomPosition =
    floatingButtonAreaPosition +
    // 퀵액션은 챗버튼 위쪽에 위치하므로 챗 버튼의 높이를 더해준다
    MOBILE_CHAT_BUTTON_HEIGHT +
    MOBILE_GAP_BETWEEN_CHAT_BUTTON_AND_QUICK_ACTIONS;

  return (
    <Styled.mobileContainer baseBottomPosition={baseBottomPosition}>
      <div
        className={`toggle-button ${opens ? "opened-actions" : ""}`}
        onClick={() => setOpens(!opens)}
      >
        <PlusSolidIcon
          color={COLOR.grayScale_800}
          width={24}
          height={24}
          className="x-mark-icon"
          onClick={noop}
        />
      </div>

      <ul className={`${opens ? "opened-actions" : "closed-actions"}`}>
        {list.map(
          ({ path, Icon, isExternalLink, label, handleClick, gtmEvent }, i) =>
            path ? (
              <Styled.mobileQuickActionItem
                type="link"
                {...getCommonQuickActionItemProps({ label, i })}
              >
                <div className="animation-wrapper">
                  <Link href={path}>
                    <a
                      target={isExternalLink ? "_blank" : "_top"}
                      rel={isExternalLink ? "noopener noreferrer" : ""}
                      onClick={() => {
                        if (!gtmEvent) {
                          return;
                        }

                        addEventToGTMDataLayer(gtmEvent);
                      }}
                    >
                      <span className="label">{label}</span>
                    </a>
                  </Link>
                </div>

                <Icon
                  color={COLOR.grayScale_800}
                  width={16}
                  height={16}
                  onClick={() => (isExternalLink ? noop : Router.push(path))}
                />
              </Styled.mobileQuickActionItem>
            ) : (
              <Styled.mobileQuickActionItem
                type="action"
                onClick={handleClick}
                {...getCommonQuickActionItemProps({ label, i })}
              >
                <div className="animation-wrapper">
                  <span className="label">{label}</span>
                </div>

                <Icon color={COLOR.grayScale_800} width={16} height={16} />
              </Styled.mobileQuickActionItem>
            )
        )}
      </ul>
    </Styled.mobileContainer>
  );
}
